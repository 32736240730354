import type { Link } from '@unhead/schema'
import { withTrailingSlash } from 'ufo'
import type { I18nHeadMetaInfo, MetaAttrs } from '@nuxtjs/i18n'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { indexedRoutes } from '~/constants/indexedRoutes'

export interface SeoHelperConfig {
  allowedParams?: string[]
  shouldBeIndexed?: boolean
  canonicalLink?: string
  disableCanonicalLink?: boolean
  meta?: MetaAttrs
  link?: MetaAttrs[]
}

type MetaItem = MetaAttrs

type LinkItem = Link

interface SeoHelperVariables {
  $i18n: { locale: string }
  $route: RouteLocationNormalizedLoaded
  headers: Readonly<Record<string, string>>
  isSubdomain: boolean
  isProduction: boolean
  $nuxtI18nHead: I18nHeadMetaInfo
}

/**
 * @description //
 */

class SeoHelper {
  private vm: SeoHelperVariables
  private readonly allowedParams: string[]
  private readonly shouldBeIndexed: boolean
  private readonly canonicalLink: string | undefined
  private readonly disableCanonicalLink: boolean
  private readonly i18nHead: I18nHeadMetaInfo
  private readonly i18nHeadMeta: MetaAttrs[]
  private meta: any
  private link: MetaAttrs[]

  constructor(vm: SeoHelperVariables, config: SeoHelperConfig) {
    this.vm = vm
    this.allowedParams = config.allowedParams || []
    this.shouldBeIndexed = config.shouldBeIndexed || false
    this.canonicalLink = config.canonicalLink || undefined
    this.disableCanonicalLink = config.disableCanonicalLink || false
    this.meta = config.meta || true
    this.link = config.link || []

    this.i18nHead = this.vm.$nuxtI18nHead

    this.i18nHead.link = [...(this.i18nHead.link || [])]
    this.i18nHeadMeta = [...(this.i18nHead.meta || [])]

    this.init()
  }

  public getCanonicalLinkTag(): LinkItem | null {
    return this.i18nHead.link!.find((item) => item.hid === 'i18n-can') || null
  }

  public getHreflangTags(): LinkItem[] {
    return (
      this.i18nHead.link!.filter((item) => item.hreflang !== undefined) || null
    )
  }

  public getCanonicalLink(): string | Promise<string | undefined> | undefined {
    return this.getCanonicalLinkTag()?.href || undefined
  }

  /**
   * @description
   */
  public createOrOverwriteMetaTag(
    metaHid: string,
    metaNewContent: string,
    metaNewName: string | null = null
  ): void {
    const metaTag = this.i18nHeadMeta.find((item) => item.hid === metaHid)

    if (metaTag) {
      metaTag.content = metaNewContent
    } else {
      this.i18nHeadMeta.push({
        hid: metaHid,
        name: metaNewName || metaHid,
        content: metaNewContent,
      })
    }
  }

  /**
   * @description
   */
  public createOrOverwriteLinkTag(hid: string, linkObj: MetaAttrs): void {
    const tag = this.i18nHead.link!.find((item) => item.hid === hid)

    if (tag) {
      Object.assign(tag, linkObj)
    } else {
      this.i18nHead.link!.push({
        hid,
        ...linkObj,
      })
    }
  }

  /**
   * @description Checks if the params are allowed
   * If there is a not allowed parameter, marks as noindex
   */
  public isQueryIsCanonical(): boolean {
    return Object.keys(this.getQueryParameters()).every((param) =>
      this.allowedParams.includes(param)
    )
  }

  public getHeadMeta(): MetaItem[] {
    return this.i18nHeadMeta
  }

  public getI18nHead(): I18nHeadMetaInfo {
    return this.i18nHead
  }

  /**
   * @description initialize helper
   */
  private init() {
    this.handlePageIndex()

    this.handleMetaTags()
    this.handleLinkTags()

    this.handleCanonicalLink()
    this.forceTrailingSlashOnHrefLangTags()
  }

  private getShouldBeIndexed() {
    return this.shouldBeIndexed
  }

  private setCanonicalLink() {
    if (this.canonicalLink) {
      const canonicalLinkTag = this.getCanonicalLinkTag()
      if (canonicalLinkTag) {
        canonicalLinkTag.href = this.canonicalLink
      }
    }
  }

  private handleCanonicalLink() {
    this.setCanonicalLink()

    if (this.disableCanonicalLink) {
      const canonicalLinkTag = this.getCanonicalLinkTag()
      if (canonicalLinkTag) {
        delete canonicalLinkTag.href
        delete canonicalLinkTag.rel
      }
    } else {
      this.forceTrailingSlashOnCanonical()
    }
  }

  private handlePageIndex() {
    const defaultRobotsContentArr = ['max-image-preview:large']
    const getRobotsMetaContent = (content: string) =>
      [content, defaultRobotsContentArr].join(', ')

    // Doesn't index as default
    this.createOrOverwriteMetaTag(
      'robots',
      getRobotsMetaContent('noindex, follow')
    )

    const locale = this.vm.$i18n.locale
    let currentRoute =
      '/' + this.vm.$route.fullPath.replace(locale, '').replace(/\//g, '')

    if (currentRoute !== '/') {
      currentRoute = currentRoute + '/'
    }

    // Indexed in case:
    // - the route is listed in indexedRoutes array
    // - SeoHelper config is marked as shouldIndex
    if (
      indexedRoutes.find((ir) => ir.path === currentRoute) ||
      this.getShouldBeIndexed()
    ) {
      this.createOrOverwriteMetaTag(
        'robots',
        getRobotsMetaContent('index, follow')
      )
    }
    // If the query is not canonical, then it can't be indexed by any means
    // If x-param header is 'param', don't index
    if (
      this.vm.headers['x-param'] === 'param' ||
      !this.isQueryIsCanonical() ||
      !this.vm.isProduction ||
      this.vm.isSubdomain
    ) {
      this.createOrOverwriteMetaTag(
        'robots',
        getRobotsMetaContent('noindex, follow')
      )
    }
  }

  private handleMetaTags() {
    Object.keys(this.meta).forEach((metaTagKey) => {
      const metaTagValue = this.meta[metaTagKey]
      if (metaTagValue && metaTagValue !== '') {
        this.createOrOverwriteMetaTag(metaTagKey, metaTagValue)
      }
    })
  }

  private handleLinkTags() {
    this.link.forEach((linkTag) => {
      this.createOrOverwriteLinkTag(linkTag.hid, linkTag)
    })

    const isArticlePage = this.vm.$route.path.match('/magazine/.*/')

    // Asked by SEO team to remove the english hreflang from the article pages
    if (isArticlePage) {
      this.i18nHead.link = this.i18nHead.link.filter((x) => x.hreflang !== 'en')
    }
  }

  private forceTrailingSlashOnCanonical() {
    const canLinkTag = this.getCanonicalLinkTag()

    if (canLinkTag && canLinkTag.href && typeof canLinkTag.href === 'string') {
      canLinkTag.href = withTrailingSlash(canLinkTag.href, true)
    }
  }

  private forceTrailingSlashOnHrefLangTags() {
    const linkTags = this.getHreflangTags()
    linkTags.forEach((tag) => {
      // We also remove the default param (added by cloudfront) from hreflang
      const href = tag.href

      if (href && typeof href === 'string') {
        const indexOfParams = href.indexOf('?')

        // Might brake if we have indexable urls with params
        tag.href = withTrailingSlash(
          href.slice(0, indexOfParams > 0 ? indexOfParams : href.length),
          true
        )
      }
    })
  }

  private getQueryParameters(): any {
    return this.vm.$route.query
  }
}

export default SeoHelper
