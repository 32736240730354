import type { UseHeadInput } from 'unhead'
import type { MergeHead, Meta } from '@unhead/schema'
import SeoHelper, { type SeoHelperConfig } from '~/utils/SeoHelper'

interface UseSeoHelperOptions extends SeoHelperConfig {
  head?: UseHeadInput<MergeHead>
  title?: string
}

const useSeoHelper = (
  options: Ref<UseSeoHelperOptions> | ComputedRef<UseSeoHelperOptions> = ref({})
) => {
  const route = useRoute()
  const { locale } = useI18n()
  const subdomain = useSubdomain()
  const headers = useRequestHeaders()

  const $i18n = { locale: locale.value }
  const config = useRuntimeConfig()
  const isProduction = config.public.appEnv === 'production'

  const seoHelper = ref<SeoHelper | undefined>(undefined)

  const localeHead = useLocaleHead({
    addSeoAttributes: {
      canonicalQueries: options.value.allowedParams || [],
    },
  })

  watchEffect(() => {
    seoHelper.value = new SeoHelper(
      {
        $i18n,
        $route: route,
        headers,
        isSubdomain: Boolean(subdomain.value),
        isProduction,
        $nuxtI18nHead: localeHead.value,
      },
      options.value
    )
  })

  useHead(
    computed(() => ({
      ...(options.value.head && options.value.head),
      meta: seoHelper.value?.getHeadMeta(),
      link: seoHelper.value?.getI18nHead().link,
    }))
  )
}

export default useSeoHelper
